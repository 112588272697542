import React from "react"

export const i18nPath = {
  activeLocale: "it",
  paths: [
    {
      locale: "en",
      value: "en",
    },
    {
      locale: "it",
      value: "",
    },
  ],
}

export const LocaleContext = React.createContext(i18nPath)

import React from "react"
import { Box } from "@theme-ui/components"
import Link from "./utils/link"
import { LocaleContext } from "../context/localeContext"

const LanguageSelector = () => {
  return (
    <LocaleContext.Consumer>
      {({ activeLocale, paths }) => {
        return (
          <Box>
            {paths
              .sort((a, b) => b.locale.localeCompare(a.locale))
              .map(link => (
                <Link
                  to={`/${link.value}`}
                  sx={{
                    marginLeft: 3,
                    color: activeLocale === link.locale ? "primary" : "dark",
                    textTransform: "uppercase",
                    fontWeight: activeLocale === link.locale ? "bold" : null,
                    "&:hover": {
                      color: "primary",
                    },
                  }}
                >
                  {link.locale}
                </Link>
              ))}
          </Box>
        )
      }}
    </LocaleContext.Consumer>
  )
}

export default LanguageSelector

import { useStaticQuery, graphql } from "gatsby"

export const useCategories = locale => {
  const menu = useStaticQuery(graphql`
    query CategoriesMenuQuery {
      allDatoCmsCategory(sort: { fields: position, order: ASC }) {
        nodes {
          id
          name
          slug
          locale
          menu
        }
      }
    }
  `)

  const i18nMenu = menu.allDatoCmsCategory.nodes.filter(
    category => category.locale === locale
  )

  return i18nMenu
}

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"

const Layout = ({ children, locale, location, allSlugLocales }) => {
  return (
    <AnimateSharedLayout>
      <Helmet>
        <meta
          name="robots"
          content="noindex nofollow noarchive nosnippet noimageindex notranslate"
        />
      </Helmet>
      <Header locale={locale} allSlugLocales={allSlugLocales}/>
      <div>
        <AnimatePresence>
          <motion.main
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            exit={{ opacity: 0 }}
            key={location ? location.pathname : null}
          >
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer locale={locale} />
      </div>
    </AnimateSharedLayout>
  )
}

export default Layout

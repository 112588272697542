import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { NavLink as ThemeUiNavLink } from "theme-ui"

const NavLink = props => {
  return <ThemeUiNavLink as={AsLink} {...props} />
}

const AsLink = props => {
  return (
    <GatsbyLink activeClassName="active" partiallyActive={true} {...props} />
  )
}

export default NavLink

import React from "react"

export const languages = {
  en: {
    locale: "en",
    firstName: "Name",
    lastName: "Last Name",
    email: "Email Address",
    employee: "I hereby declare that I am an AB employee",
    disclaimer:
      "The information transmitted through this site is intended solely for AB employees and must be considered to be confidential in nature. It is forbidden to use and disseminate such information without the appropriate express authorization.",
    newsletterDisclaimer:
      "I have read and accept <a href='https://www.gruppoab.com/privacy-policy/' target='_blank'>the privacy policy</a>.",
    subscribe: "Stay updated on the latest AB news, ",
    subscribeLink: "directly on your email",
    loading: "Loading...",
    success: "Registration confirmed!",
    subscribeButton: "Subscribe to the newsletter",
    filterByCategory: "Filter by category:",
    allCategories: "All categories",
    anchorText: "Flip past editions",
  },
  it: {
    locale: "it",
    firstName: "Nome",
    lastName: "Cognome",
    email: "Indirizzo Email",
    employee: "Dichiaro di essere un dipendente AB",
    disclaimer:
      "Le informazioni trasmesse attraverso questo sito sono dirette esclusivamente ai dipendenti AB e devono ritenersi riservate con divieto di diffusione e di uso salva espressa autorizzazione.",
    newsletterDisclaimer:
      "Ho letto e accetto <a href='https://www.gruppoab.com/it/privacy-policy/' target='_blank'>l'informativa sulla privacy</a>.",
    subscribe: "Rimani aggiornato sulle ultime news di AB, ",
    subscribeLink: "direttamente sulla tua email",
    loading: "Caricamento...",
    success: "Iscrizione confermata!",
    subscribeButton: "Iscriviti alla newsletter",
    filterByCategory: "Filtra per categoria:",
    allCategories: "Tutte le categorie",
    anchorText: "Sfoglia le versioni precedenti",
  },
}

export const i18nContext = React.createContext(languages.it)
